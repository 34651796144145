import React from 'react';
import './Catering.css';
import ImageHeader from '../ImageHeader';
import SubSectionItem from '../SubSectionItem';
import {Fade} from 'react-awesome-reveal';
import {Zoom} from 'react-awesome-reveal';
// import Carousel from "../Carousel";

export default function Catering() {

  // const reviews = [
  //   <p className='review_txt'>"You don’t usually get food or service of this calibre outside a big city. Dunoon is so fortunate to have this wonderful restaurant back again"</p>,
  //   <p className='review_txt'>"If you're looking to share some special moments with family and friends, Chatters will make them all the more memorable."</p>,
  //   <p className='review_txt'>"The warm inviting atmosphere is unique to Chatters. We were welcomed like VIPs and made to feel so special and appreciated all evening."</p>,
  //   <p className='review_txt'>"Chatters is back with friendly and efficient staff, fabulous food and an evening that is a special event every time you go."</p>,
  //   <p className='review_txt'>"The smoothness of the intricacies of service, the staff floating like ballerinas as glasses were refilled and plates served was like being in one of the world’s capital cities"</p>,
  // ];

  return <>
        <ImageHeader
        headerText='CATERING' 
        imgPath={require('../../images/FionaHigginsPhotography650of157.jpg')}
        imgPathNarrow={require('../../images/FionaHigginsPhotography650of157Narrow.jpg')}
        isHero={true}
        />
        <div className='catering_body'>
          <Zoom className='zoom-container' triggerOnce={true}>
          <p className='catering_txt'><b>Chatters catering</b> specialises in crafting bespoke events, perfect for weddings, birthday celebrations, anniversaries, and everything in between. Our menu options range from simple buffet-style meals to grand feasts. Whatever the occasion, we'll ensure it's unforgettable.</p>
          </Zoom>
          <div className='triple_img_container'>
          <Zoom className='zoom-container' triggerOnce={true}>
            <img
              className='triple_img'
              alt='Potato Salad'
              src={require('../../images/FionaHigginsPhotography 652 of 157.jpg')}
              loading="eager"
            />
          </Zoom>
          <Zoom className='zoom-container' triggerOnce={true}>
            <img
              className='triple_img'
              alt='Staff'
              src={require('../../images/FionaHigginsPhotography 359 of 58.jpg')}
              loading="eager"
            />
          </Zoom>
          <Zoom className='zoom-container' triggerOnce={true}>
            <img
              className='triple_img'
              alt='Canapes'
              src={require('../../images/chicken-restaurant.jpg')}
              loading="eager"            />
          </Zoom>
          </div>
          <Zoom className='zoom-container' triggerOnce={true}>
          <h2 className="wedding_title" style={{marginBottom:'2px'}}>WEDDINGS</h2>
          <div className='header_underline_2'/>
          </Zoom>
          <Zoom className='zoom-container' triggerOnce={true}>
          <p className='catering_txt' style={{marginBottom:'5px', marginTop:'0px'}}>Our wedding planning service offers far more than just a meal. We work with a carefully selected group of experts who will cater to your every requirement. All Chatters weddings are meticulously planned to make it truly a day to remember!</p>
          </Zoom>
          <SubSectionItem
            src={require('../../images/chatters_wedding_new.png')}
            text={["- Venues, marquees and decor", "- Floral decorations and corsages", "- Favours and personalised mementoes", "- Bands, discos and pipers", "- Wedding cars and carriages", "- Wines, Champagnes and bars", "- Wedding photography and videos"]}
            list={true}
          />
          {/*<Carousel*/}
          {/*  reviews={reviews}*/}
          {/*  title="TESTIMONIALS"*/}
          {/*/>        */}
          <div className='catering_quote_container'>
            <Zoom className='zoom-container' triggerOnce={true}>
              <p className='catering_quote' style={{marginBottom:'15px'}}>"From a village hall to a baronial ball or an island croft to a city loft...</p>
            </Zoom>
              <Fade direction={'left'} duration={1500} triggerOnce={true}>
                  <img
                      className='chatters_van_icon'
                      alt='Van Icon'
                      src={require('../../images/chatters-van-white.png')}
                      loading="eager"
                  />
              </Fade>
            <Zoom className='zoom-container' triggerOnce={true}>
            <p className='catering_quote' style={{marginTop:'10px'}}>Chatters food, wine and service, at your location, for your special occasion."</p>
            </Zoom>
          </div>
          <SubSectionItem
            src={require('../../images/chatters-window-front.jpg')}
            title='OUR RESTAURANT YOUR VENUE'
            text={["Our restaurant is available for private hire. Chatters can provide the unique surrounding, tailored catering and attentive service to make your event extra special!"]}
            flip={true}
          />
          <Zoom className='zoom-container' triggerOnce={true}>
          <p className='catering_txt'>For all catering enquiries please contact katie@chattersrestaurant.co.uk or call 07879472898.</p>
          </Zoom>
          <div className="break" style={{marginBottom:'60px'}}></div>
        </div>
    </>
}