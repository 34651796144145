import React from 'react'
import {Zoom} from 'react-awesome-reveal';
import './SubSectionItem.css'
import { useNavigate } from "react-router-dom";

function SubSectionItem(props) {

  let underline;
  if(props.title){
    underline = <div className='header_underline_2'/>
  }

  let style;
  if(props.flip){
    style={
      flexDirection:'row-reverse',
    }
  }

  let style1;
  let style2;
  if(props.list){
    style1={
      marginTop:'10px',
    }
    style2={
      textAlign:'center'
    }
  } else {
    style1={
      marginTop:'15px'
    }
  }

  let navigate = useNavigate();

    const handleGoTo = () => {
      window.scrollTo(0, 0);

      navigate(`/${props.title.toLowerCase()}`);
    }

  return (
    
        <div className='sub_sec_container' style={style}>
            <div className='sub_sec_item_container'>
              <Zoom triggerOnce={true}>
              <h2 className='sub_sec_item_title' >{props.title}</h2>
              </Zoom>
              <Zoom triggerOnce={true}>
              {underline}
              </Zoom>
              <Zoom triggerOnce={true}>
              <div className='sub_sec_item_txt'>
              {
                props.text.map((item, index) => {
                  if (props.text[props.text.length - 1] === item){
                    return <span className='span_item_txt' style={style2} key={index}>{item}</span>
                  } else {
                    return  <>
                              <span className='span_item_txt' style={style2} key={index}>{item}</span>
                              <div className="break" style={style1} key={index+1}></div>
                            </> 
                  }
                })}
                { props.isLink &&
                <button onClick={() => handleGoTo()}  style={{ boxShadow:'none'}} className='go_to_btn'>GO TO {props.title} &#8680;</button>
                }
              </div>
              </Zoom>
            </div>
          <Zoom className='zoom-container' triggerOnce={true}>
            <img
                className='sub_sec_item_img'
                alt='Home'
                src={props.src}
                loading="eager"
            />
          </Zoom>
        </div>
  )
}

export default SubSectionItem