import React from 'react';
import './Menus.css';
import MenuCards from './MenuCards';
import {Zoom} from 'react-awesome-reveal';

export default function Menus() {
  return (
    <div className='menus_body'>
      <Zoom triggerOnce={true}>
        <h2>MENUS</h2>
        <div className='header_underline_2'/>
      </Zoom>
      <Zoom className='zoom-container' triggerOnce={true}>
          <p className='menus_txt'>Our menu changes throughout the year, from à la carte to themed evenings and wine events - featuring fresh seasonal produce and local seafood.</p>
      </Zoom>
      <MenuCards />
      <div className="break" style={{marginBottom:'10px'}}></div>
    </div>
  );
}
