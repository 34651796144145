import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import './Navbar.css';
import '../fonts/Gill Sans Nova Cn Medium.ttf'
import '../fonts/Gill Sans Nova Cn Light.ttf'
import 'react-modern-drawer/dist/index.css'



function Navbar() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  return (
    <>
      <nav className='navbar'>
        <div className='navbar-container'>
          <Link to='/' className='navbar-title' onClick={() => {
            window.scroll(0, 0);
            closeMobileMenu()
          }}>CHATTERS</Link>
          <div className='navbar-logo'></div>
          <div className={click ? 'menu-btn open' : 'menu-btn'} onClick={handleClick}>
            <div className='menu-btn__burger'></div>
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
          <li className='nav-item'>
            <Link  
              to='/' 
              className='nav-links' 
              onClick={() => {closeMobileMenu(); window.scroll(0, 0);}}
              >
              HOME
            </Link>
            </li>
            <li className='nav-item'>
            <Link  
              to='/restaurant' 
              className='nav-links' 
              onClick={() => {closeMobileMenu(); window.scroll(0, 0);}}
              >
              RESTAURANT
            </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/catering'
                className='nav-links'
                onClick={() => {closeMobileMenu(); window.scroll(0, 0);}}
              >
                CATERING
              </Link>
            </li>
            <li className='nav-item'>
            <a href='https://squareup.com/gift/MLNJEJHJ7K2HG/order' target='_blank' rel="noreferrer" className='nav-links'>VOUCHERS</a>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
