import React, {useEffect, useState} from 'react';
import '../App.css';
import './ImageHeader.css';
import {Fade} from 'react-awesome-reveal';

function ImageHeader(props) {
  var style;
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Function to update width state when window is resized
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    // Attach event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  if (width <= 580) {
    style = {
      backgroundImage: `url(${props.imgPathNarrow})`
    };
  } else {
    style = {
      backgroundImage: `url(${props.imgPath})`
    };
  }

  return (
    <div className='header-container'>
      <div className='img-container' style={style}></div>
      {props.isHero &&
        <Fade direction={'up'} duration={2000} distance={"150px"} delay={500} triggerOnce={true}>
          <h1 className={'header-txt'}>{props.headerText}</h1>
        </Fade>
      }
    </div>
  );
}

export default ImageHeader;
