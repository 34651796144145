import React from 'react'
import './SubSection.css'
import SubSectionItem from './SubSectionItem'

const HomeSubSection = () => {
  return (
    <div className='sub_sec'>
        <SubSectionItem
            src={require('./../images/FionaHigginsPhotography 949 of 192.jpg')}
            title="ABOUT US"
            text={["We are a family-run business based in Dunoon on the west coast of Scotland. Starting out as a restaurant 30 years ago, Chatters has since expanded into catering and bespoke events, whilst now running our restaurant as a pop-up. We pride ourselves in our attentive and personalised service, using the best of local Scottish ingredients to create delicious food and lasting memories when people are brought together."]}
        />
        <div className="break"></div>
        <SubSectionItem
            src={require('../images/chatters-sitting-room-to-dining.jpg')}
            title="RESTAURANT"
            text={['Chatters restaurant opened in 1991 and with several awards, was recognised as one of the finest dining experiences in Argyll... After 8 years of focusing on outside catering we have re-opened the doors of our family-run restaurant once again - this time as a pop-up.']}
            flip={true}
            isLink={true}
        />
        <div className="break"></div>
        <SubSectionItem
            src={require('../images/FionaHigginsPhotography 654 of 157.jpg')}
            title="CATERING"
            text={["Chatters catering specialises in crafting bespoke events, perfect for weddings, birthday celebrations, anniversaries, and everything in between. Our menu options range from simple buffet-style meals to grand feasts. Whatever the occasion, we'll ensure it's unforgettable."]}
            isLink={true}
        />
        <div className="break"></div>
    </div>
  )
}

// Good text
// Our passion for fine food is matched only by the warmth of the welcome. We create a complete dining experience to be treasured and returned to time and time again.


export default HomeSubSection