import React from 'react'
import './Map.css';

const Map = (props) => {
  return (
    <>
    <iframe 
        src={props.src}
        className='map_container'
        allowFullScreen="" 
        referrerPolicy="no-referrer-when-downgrade"
        title='chatters_map'
        loading='eager'
        style={{width:props.width, height:props.height, borderRadius:props.radius}}
        >
    </iframe>
    </>
  )
}

export default Map