import React from 'react';
import './Cards.css';
import CardItem from './CardItem';
import WhiskyMenu from '../menus/whisky-menu.pdf';
import SampleMenu from '../menus/sample-menu-for-website.pdf';
import DrinksMenu from '../menus/Chatters-Bar-List-Final.pdf';
import WineList from '../menus/Chatters-Wine-List-Final.pdf';

function Cards() {
  return (
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src={require('../images/chatters-venison-scallops.jpg')}
              text='SAMPLE MENUS'
              path={SampleMenu}
              isLink={false}
              id={1}
            />
            <CardItem
              src={require('../images/chatters-wine-reflection.jpg')}
              text='WINE LIST'
              path={WineList} 
              isLink={false}   
              id={2}             
            />
            <CardItem
              src={require('../images/chatters-whisky-cabinet.jpg')}
              text='WHISKY'
              path={WhiskyMenu}  
              isLink={false}
              id={3}                
            />
            <CardItem
              src={require('../images/harris_gin.jpg')}         
              text='DRINKS'
              path={DrinksMenu}
              isLink={false} 
              id={4}                            
            />
          </ul>
        </div>
      </div>
  );
}

export default Cards;
