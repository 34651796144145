import React from 'react';
import './Footer.css';
import { useLocation } from 'react-router-dom'
import AllergenDisclaimer from '../policies/allergen_disclaimer.pdf';



function Footer() {
  const location = useLocation();


  return (
    <div className='footer-container'>
      <div className='footer-links'>
        <div className='footer-link-wrapper'>
          {location.pathname.includes('market') || location.pathname.includes('checkout') ? 
          <div className='footer-link-social'>
            <h3>INFO & POLICIES</h3>
            <div className='header_underline_1' style={{backgroundColor:'#fff'}}/>
            <a href='https://www.facebook.com/chatters.dunoon' rel="noreferrer" target='_blank'>FAQs</a>
            <a href={AllergenDisclaimer} rel="noreferrer" target='_blank'>Allergen Disclaimer</a>
            <a href='https://www.facebook.com/chatters.dunoon' rel="noreferrer" target='_blank'>Refund Policy</a>
          </div>
          :
            <div className='footer-link-find'>
              <h3>FIND US</h3>
              <div className='header_underline_1' style={{backgroundColor:'#fff'}}/>
              <span>58 John St, Dunoon</span>
              <span>PA23 8BJ</span>
            </div>
          }
          <div className='footer-link-enquiries'>
            <h3>ENQUIRIES</h3>
            <div className='header_underline_1' style={{backgroundColor:'#fff'}}/>
            <span>katie@chattersrestaurant.co.uk</span>
            <span>07879472898</span>
          </div>
          <div className='footer-link-social'>
            <h3>SOCIAL MEDIA</h3>
            <div className='header_underline_1' style={{backgroundColor:'#fff'}}/>
            <a href='https://www.facebook.com/chatters.dunoon' rel="noreferrer" target='_blank'>Facebook</a>
            <a href='https://www.instagram.com/chattersdunoon' rel="noreferrer" target='_blank'>Instagram</a>
          </div>
        </div>
      </div>
      <section className='social-media'>
        <div className='social-media-wrap'>
          <div className='footer-logo'></div>
          <small className='website-rights'>CHATTERS © {new Date().getFullYear()}</small>
          <div className='social-icons'>
            <a
              className='social-icon-link facebook'
              href='https://www.facebook.com/chatters.dunoon'
              target='_blank'
              aria-label='Facebook'
              rel="noreferrer"
            >
              <i className='fab fa-facebook-f' />
            </a>
            <a
              className='social-icon-link instagram'
              href='https://www.instagram.com/chattersdunoon'
              target='_blank'
              aria-label='Instagram'
              rel="noreferrer"
            >
              <i className='fab fa-instagram' />
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
