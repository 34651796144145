import React from 'react';
import Navbar from './components/Navbar';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import Home from './components/pages/Home';
import Restaurant from './components/pages/Restaurant';
import Footer from './components/Footer'
import Catering from './components/pages/Catering';


function App() {

  return (
      <>
        <Navbar/>
        <Routes>
        <Route path='/' element={<Home />} />
        <Route path='restaurant' element={<Restaurant />} />
        <Route path='catering' element={<Catering />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;






