import React from 'react'
import HeroCarousel from './../HeroCarousel';
import HomeSubSection from '../HomeSubSection';

const Home = () => {

    const carouselItems = [
        { src: require('../../images/chatters-dining.jpg'), srcNarrow: require('../../images/chatters-dining-narrow.jpg'), title: "RESTAURANT" },
        { src: require('../../images/FionaHigginsPhotography650of157.jpg'), srcNarrow: require('../../images/FionaHigginsPhotography650of157Narrow.jpg'), title: "CATERING" },
    ];

    

  return (
    <div style={{marginBottom:'40px'}}>
        <HeroCarousel items={carouselItems} />
        <HomeSubSection/>
    </div>
  )
}

export default Home