import React from "react";
import "./Reservations.css";
import { Zoom } from "react-awesome-reveal";
import OpeningDays from "./OpeningDays";

export default function Reservations() {
  return (
    <>
      <div className="res_body">
        <Zoom triggerOnce={true}>
          <h2>OPENING DATES</h2>
          <div className="header_underline_2" />
        </Zoom>
        <Zoom className="zoom-container" triggerOnce={true}>
          <span className="res_txt">
            To make a reservation please email katie@chattersrestaurant.co.uk
            with your preferred date and time or call 07879472898.
          </span>
        </Zoom>
        <OpeningDays />
        <div className="break" style={{ marginBottom: "15px" }}></div>
      </div>
    </>
  );
}
