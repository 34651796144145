import React from 'react'
import { useEffect, useState } from "react";
import { useSwipeable } from "react-swipeable";
import './Carousel.css';
import {Zoom} from 'react-awesome-reveal';

const Carousel = (props) => {

    const data = props.reviews

    const [currentIndex, setCurrentIndex] = useState(0);
    const [paused, setPaused] = useState(false);
    const carouselInfiniteScroll = () => {
        if(!paused){
            if (currentIndex === data.length-1) {
                return setCurrentIndex(0);
            } 

        
            return setCurrentIndex(currentIndex+1)
        }
        
    }
        
    useEffect(() => {
        const interval = setInterval(() => {carouselInfiniteScroll()}, 7000)
        return () => clearInterval(interval)
    })

    const swipeLeft = () => {
        if (currentIndex === data.length-1){
            return setCurrentIndex(0);
        } 

        setCurrentIndex(currentIndex + 1)
    };

    const swipeRight = () => {
        if (currentIndex === 0){
            return setCurrentIndex(data.length-1)
        }

        setCurrentIndex(currentIndex - 1)
    };
    

    const handlers = useSwipeable({
        onSwipedLeft: swipeLeft,
        onSwipedRight: swipeRight,
        preventScrollOnSwipe: true
    });

        return(
            <div className="carousel_scratch" 
            onMouseEnter={() => setPaused(true)}
            onMouseLeave={() => setPaused(false)}
            {...handlers}>
                <Zoom triggerOnce={true}>
                <h2 className="reviews_title" style={{marginBottom:'2px'}}>{props.title}</h2>
                <div className='header_underline_2' style={{  backgroundColor: '#fff', marginBottom:'10px'}}/>
                </Zoom>
                <div className="carousel-container" >
                    { data.map((item, index) => {
                        return <div className="carousel-item"
                                    style={{transform: `translate(-${currentIndex * 100}%)` }}
                                    key={index}>
                                    {item}
                                    </div>
            
                    })}
                </div>
                <div className="indicators">
                        { data.map((item, index) => {
                            return <div
                                    className={`${index === currentIndex ? "active" : "inactive"}`}
                                    onClick={() => {
                                    setCurrentIndex(index);
                                    }}
                                    key={index}>
                                        <i className='fa fa-circle' style={{color:'white', margin:'5px', fontSize:'9px'}} />
                                </div>
                        
                        })}
                </div> 
            </div>
            
        )
}


export default Carousel;
