import React from 'react'
import './SubSection.css'
import SubSectionItem from './SubSectionItem'

const SubSection = () => {
  return (
    <div className='sub_sec'>
        <SubSectionItem
            src={require('../images/chatters-front-canopy.jpg')}
            title="FIND US"
            text={["You can find our restaurant nestled in the centre of Dunoon, only a five minute walk from the pier and opposite the local cinema. The Chatters team look forward to welcoming you soon!"]}
        />
        <div className="break"></div>
        <SubSectionItem
            src={require('../images/chatters-sitting-room.jpg')}
            title="SITTING ROOM"
            text={['Read the menus and relax with an aperitif in our cosy sitting room - after your meal return to enjoy a dram, liqueur or coffee with handmade petits fours.']}
            flip={true}
        />
        <div className="break"></div>
        <SubSectionItem
            src={require('../images/chatters_dine_candlelight.jpg')}
            title="DINING"
            text={["Dine by candlelight next to an open fire in a unique atmosphere or al fresco on a warm summers evening in our secluded garden.","Enjoy the best of Scottish produce complemented with a wide selection of wines. Complete your dining experience with dessert from Chatters' pièce de résistance - the 'famous' homemade pudding trolley."]}
        />
        <div className="break"></div>
    </div>
  )
}

// Good text
// Our passion for fine food is matched only by the warmth of the welcome. We create a complete dining experience to be treasured and returned to time and time again.


export default SubSection