import React from 'react';
import { Link } from 'react-router-dom';
import {Zoom} from 'react-awesome-reveal';

function CardItem(props) {
  return (
    <>
    <Zoom triggerOnce={true}>
        <li className='cards__item' key={props.id}>
            {props.isLink
              ? <Link
              className='cards__item__link'
              alt='Link Image'
              to={props.path}
              style={{
                backgroundImage: `url(${props.src})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
              }}
              onClick={() => {
                window.scroll(0, 0);
              }}
              >
                <div className='cards__item__info'>
                  <h2 className='cards__item__text'>{props.text}</h2>
              </div>
              </Link>
              : <a 
                  className='cards__item__link'  
                  href={props.path} 
                  target="_blank" 
                  rel="noreferrer" 
                  style={{
                    backgroundImage: `url(${props.src})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                  }}>
                <div className='cards__item__info'>
                  <h2 className='cards__item__text'>{props.text}</h2>
                </div>
              </a>
            }
        </li>
      </Zoom>
    </>
  );
}

export default CardItem;
