import React,{useState, useEffect} from 'react'
import './HeroCarousel.css'
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const HeroCarousel = ({ items }) => {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
      // Function to update width state when window is resized
      const handleResize = () => {
        setWidth(window.innerWidth);
      };

      // Attach event listener for window resize
      window.addEventListener('resize', handleResize);

      // Cleanup the event listener when the component unmounts
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

  return (
    <Carousel
      showArrows={true}
      showStatus={false}
      showIndicators={true}
      showThumbs={false}
      swipeable={false}
      autoPlay={true}
      infiniteLoop={true}
      interval={4000}
      animationHandler={'fade'}
      transitionTime={2000}
      stopOnHover={false}
    >
      {items.map((item, index) => {
        const style =
          width <= 550
            ? {
                backgroundImage: `url(${item.srcNarrow})`,
              }
            : {
                backgroundImage: `url(${item.src})`,
              };

        return (
          <div className='hero_container' key={index}>
            <div className='img_container' style={style} loading='eager'></div>
            <div className="img_carousel_title_container">
              <h1>{item.title}</h1>
            </div>
          </div>
        );
      })}
    </Carousel>
  );
}

export default HeroCarousel